*{
  transition: all .3s linear;
}
body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transition: all .3s linear;
  background: #fbfbfc;
}
a{
  text-decoration: none;
  color: #000;
}
.container{
  width: 80%;
  margin: auto;
}
.container-events{
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.events{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
.participants{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 20px;
}
@media (max-width: 1680px) {
  .events, .participants{
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1220px) {
  .events, .participants{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 800px) {
  .events, .participants{
    grid-template-columns: 1fr;
  }
}
.block{
  background: #def3ef;
  box-shadow: 5px 15px 25px rgba(0, 0, 0, 0.055);
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.block:hover{
  transform: scale(1.01);
}
.info{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
h1{
  font-size: 36px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
h2{
  font-size: 24px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
h3{
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.actions{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.btn{
  background: #000;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}
.btn:hover{
  transform: scale(1.1);
}
.primary{
  color: aliceblue;
  background: rgb(168,56,42);
  background: linear-gradient(180deg, rgba(168,56,42,1) 0%, rgba(221,113,84,1) 100%);
}
.secondary{
  background: transparent;
  border: 2px solid #22463f;
  color: #22463f;
}
.info>div{
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}
.organizer{
  color: #22463f;
}
.container-participants{
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.fullname{
  font-size: 18px;
  font-weight: 600;
  color: #22463f;
}
.register{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.email{
  font-size: 14px;
}
form{
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #def3ef;
  padding: 40px;
  border-radius: 20px;
  align-items: center;
}
form>div{
  display: flex;
  align-items: center;
}
.formInput{
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  font-weight: 600;
  color: #22463f;
  position: relative;
}
.error{
  position: absolute;
  bottom: -20px;
  right: 10px;
  color: rgb(168,56,42);
  font-weight: 400;
  font-size: 14px;
}
.formInput input{
  width: 50%;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}
.formInputGroup{
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 400;
  position: relative;
}
.formInputGroup>div{
  display: flex;
  align-items: center;
  gap: 5px;
}
button{
  cursor: pointer;
  outline: none;
  border: none;
  width: fit-content;
}
.search{
  outline: none;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #aaa;
  background: transparent;
  font-size: 18px;
}
.sortBy{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  position: relative;
}
.sortBy>span:nth-of-type(1){
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.sortBy>div{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fbfbfc;
  padding: 30px;
  border-radius: 20px;
  bottom: -300px;
}
.sortBy>div>div{
  display: flex;
  align-items: center;
  gap: 10px;
}
.hidden{
  display: none !important;
  opacity: 0;
}
.go-back{
  font-weight: 600;
  width: fit-content;
  padding: 5px;
}
.go-back:hover{
  background: #def3ef;
  color: #22463f;
}